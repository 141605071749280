import { Outlet, useNavigate } from "react-router-dom";
import Loading from "../ions/Loading";
import PageHeader from "../molecules/PageHeader";
import useFetch from "../../hooks/useFetch";
import ATable, { ATableHeader } from "../organisms/ATable";
import { ReactNode, useRef } from "react";
import PageFooter from "../molecules/PageFooter";

interface BreadcrumbItem {
    label: string;
    url: string;
}

type PageListProps = {
    pageTitle: string;
    url: string;
    breadcrumb: BreadcrumbItem[];
    children: {
        controlSlot: ReactNode;
        filterSlot: ReactNode;
    },
    tableHeaders?: ATableHeader[];
    tableFieldSort: string;
    tableOrderSort: "ASC" | "DESC";
    onTableSort: (field: string, order: "ASC" | "DESC") => void;
    renderRows: (data: never[]) => ReactNode;
    page: number;
    pageSize: number;
    onPageChange: (page: number) => void;
    onItemsPerPageChange: (pageSize: number) => void;
    itemsLabel: string;
    defaultBackendHeader?: boolean;
}


const PageList = ({ pageTitle, url, breadcrumb,
    children, tableHeaders, tableFieldSort, tableOrderSort, onTableSort,
    renderRows, page, pageSize, onPageChange, onItemsPerPageChange,
    defaultBackendHeader = true,
    itemsLabel }: PageListProps) => {

    const navigate = useNavigate();

    const rows = useRef([]);
    const columns = useRef<ATableHeader[]>([]);
    const size = useRef(0);
    const apiVersion = useRef('');
    const totalPages = useRef(0);
    const totalElements = useRef(0);
    const numberOfElements = useRef(0);

    const { controlSlot, filterSlot } = children;

    const { isLoading, data, error, isTokenError } = useFetch(url);

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    if (!defaultBackendHeader && tableHeaders)
        columns.current = tableHeaders;

    if (data) {
        size.current = data.size;
        totalPages.current = data.totalPages;
        totalElements.current = data.totalElements;
        numberOfElements.current = data.numberOfElements;

        apiVersion.current = `${data.apiName} - ${data.apiVersion}`;

        if (defaultBackendHeader)
            columns.current = data.headers;

        rows.current = data.list;
    }

    return (
        <div className='px-4'>
            {isLoading && <Loading />}
            <PageHeader
                title={pageTitle}
                apiVersion={apiVersion.current}
                breadcrumb={breadcrumb} >
                <div className='pt-4'>
                    {controlSlot}
                </div>
            </PageHeader>

            {filterSlot}

            <ATable headers={columns.current}
                fieldSorted={tableFieldSort}
                orderSorted={tableOrderSort}
                onSort={onTableSort}
                autoResize={true}
            >
                {renderRows(rows.current)}
            </ATable>

            <PageFooter currentPage={page}
                totalPages={totalPages.current}
                itemsPerPage={pageSize}
                totalElements={totalElements.current}
                onPageChange={onPageChange}
                onItemsPerPageChange={onItemsPerPageChange}
                itemsLabel={itemsLabel}
            />

            <Outlet />
        </div>
    );
};

export default PageList;