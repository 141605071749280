import { useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";

import TextEdit from "../../molecules/TextEdit";
import useFetch from "../../../hooks/useFetch";
import PageForm from "../../templates/PageForm";
import { ImCancelCircle } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { useSignal } from "@preact/signals-react";
import { Auth } from "aws-amplify";
import { toast } from 'react-toastify';

const FEATURE_FLAG_ITEM_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/feature-flags/page-detail`;
const FEATURE_FLAG_PATCH_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/feature-flags`;
const FEATURE_FLAG_LIST = "/feature-flags/referral/0/40/id/DESC";

interface FeatureFlagOccurrence {
    featureFlagId: string;
    number: number;
    username: string;
    createdAt: string;
    status: string;
    active: boolean;
}

const FeatureFlagViewPage = () => {
    const navigate = useNavigate();

    const isLoadingForm = useSignal(false);

    const { id } = useParams();

    const { isLoading, data, error, isTokenError } = useFetch(`${FEATURE_FLAG_ITEM_URL}/${id}`);

    const apiVersion = useRef('');

    if (data)
        apiVersion.current = `${data.apiName} - ${data.apiVersion}`;

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }

    const breadcrumb = [
        { label: "Feature flags", url: FEATURE_FLAG_LIST },
        { label: `#${id}`, url: "#" }
    ];

    const getCssColorBy = (active: boolean | undefined) => {
        return active ? 'bg-green-500' : 'bg-red-500';
    };

    const toggleStatus = async () => {
        isLoadingForm.value = true;

        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        const response = await fetch(`${FEATURE_FLAG_PATCH_URL}/${id}/status`, {
            method: "PATCH",
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            toast.success("Feature flag atualizada com sucesso!");
            navigate(FEATURE_FLAG_LIST);
        } else if (response.status == 404) toast.error("Registro não foi encontrado para ser atualizar!")
        else {
            const { message } = await response.json();
            toast.error(message);
        }

        isLoadingForm.value = false;
    };

    return (
        <PageForm
            pageTitle='Feature flag'
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={breadcrumb}
        >
            {{
                formSlot: (
                    data ? (
                        <div className="grid grid-cols-12 gap-6 mt-6">
                            <div className="col-span-6">
                                <div className="p-4 m-2 space-y-2.5">

                                    <TextEdit label="ID:" value={data?.presenter.id || ""} disabled />
                                    <TextEdit label="Nome:" value={data?.presenter.name || ""} disabled />
                                    <TextEdit label="Descrição:" value={data?.presenter.description || ""} disabled />
                                    <TextEdit label="Status:" value={data?.presenter.status || ""} disabled />
                                    <TextEdit label="Atualizado por:" value={data?.presenter.updatedBy || ""} disabled />
                                    <TextEdit label="Criado em:" value={data?.presenter.createdAt || ""} disabled />
                                    <TextEdit label="Atualizado em:" value={data?.presenter.updatedAt || ""} disabled />
                                </div>
                            </div>
                            <div className="col-span-6 m-2">
                                <h2 className="text-lg font-semibold mb-4">Ocorrências</h2>
                                <div className="overflow-y-auto max-h-96">
                                    <table className="min-w-full border border-gray-300 divide-y divide-gray-200">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">#</th>
                                                <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Usuário</th>
                                                <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Data</th>
                                                <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 uppercase">Status</th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {data?.presenter.occurrences
                                                .map((occurrence: FeatureFlagOccurrence) => (
                                                    <tr key={occurrence.number} className="hover:bg-gray-100">
                                                        <td className="py-2 px-4 text-sm text-gray-700">{occurrence.number}</td>
                                                        <td className="py-2 px-4 text-sm text-gray-700">{occurrence.username}</td>
                                                        <td className="py-2 px-4 text-sm text-gray-700">{occurrence.createdAt}</td>
                                                        <td className="py-2 px-4 text-sm text-gray-700">

                                                            <span className={`rounded px-1 py-1 text-xs font-bold text-white ${getCssColorBy(occurrence.active)}`}>
                                                                {occurrence.status}
                                                            </span>
                                                        </td>

                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    ) : null
                ),
                controlSlot: (
                    <div className='pt-4 flex justify-end' >
                        {data?.presenter.active ?
                            <button
                                type="button"
                                className="text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded text-sm px-5 py-2.5 text-center"
                                onClick={toggleStatus}
                                disabled={isLoading || isLoadingForm.value}
                            >
                                <div className="flex items-center">
                                    <ImCancelCircle className="mr-2" />
                                    Desativar
                                </div>
                            </button>
                            :
                            <button
                                type="button"
                                className="text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded text-sm px-5 py-2.5 text-center"
                                onClick={toggleStatus}
                                disabled={isLoading || isLoadingForm.value}
                            >
                                <div className="flex items-center">
                                    <FaCheck className="mr-2" />
                                    Ativar
                                </div>
                            </button>
                        }
                    </div>
                )
            }}
        </PageForm >
    );
};

export default FeatureFlagViewPage;
