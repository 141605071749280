import { FC } from "react";
import { FaHandshake, FaUser } from "react-icons/fa";
import NewTooltip from "../atoms/NewTooltip";


type ReferralOriginProps = {
    id: number,
    title: string,
    environment: string;
}

const ReferralOriginIcon: FC<ReferralOriginProps> = ({ id, title, environment }) => {

    if (environment === "external")
        return (
            <NewTooltip content={title}>
                <FaHandshake style={{ color: "cornflowerblue" }} />
            </NewTooltip>
        );

    return (
        <NewTooltip content={title}>
            <FaUser style={{ color: "cornflowerblue" }} />
        </NewTooltip>
    );

}

export default ReferralOriginIcon;