import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';

import PageList from '../../templates/PageList';

const REASON_LIST_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/reasons/page-list`;

type Reason = {
    id: string;
    description: string;
    analysisType: string;
    analysisAction: string;
    active: boolean;
};

const ReasonPageList = () => {
    const navigate = useNavigate();

    const { page, pageSize, field: fieldSorted, order: orderSorted, filter }: any = useParams();

    let txtReasonDescription = '';
    let analysisType = '';

    if (filter) {
        [txtReasonDescription = '', analysisType = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));
    }
    const pageTitle = analysisType === 'PRE_ANALYSIS' ? 'Motivos da pré-análise' : 'Motivos (pedido de empréstimo)';

    const url = `${REASON_LIST_URL}?search=${txtReasonDescription}&analysisType=${analysisType}&page=${page}&pageSize=${pageSize}&order=${orderSorted}`;

    const getCssColorBy = (active: boolean | undefined) => {
        return active ? 'bg-green-500' : 'bg-red-500';
    };

    const renderRows = (rows: Reason[]) => {
        if (!rows || rows.length === 0) {
            return (
                <tr>
                    <td colSpan={5} className="py-2 px-2 text-center">
                        <div className="text-2xl font-bold text-gray-500 mt-4">
                            Nenhum motivo cadastrado...
                        </div>
                    </td>
                </tr>
            );
        }

        return rows.map((reason: Reason, index: number) => (
            <tr
                key={reason.id}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border-gray-300`}
            >
                <td className="py-2 px-2">

                    {reason.analysisAction === 'REJECT' ?
                        <span className="rounded px-1 py-1 text-xs font-bold text-white bg-pink-700">
                            Reprovação
                        </span>
                        :
                        <span className="rounded px-1 py-1 text-xs font-bold text-white bg-blue-500">
                            Aprovação
                        </span>
                    }

                </td>
                <td className="py-2 px-2">{reason.description}</td>
                <td className="py-2 px-2" align="center">
                    <span className={`rounded px-1 py-1 text-xs font-bold text-white ${getCssColorBy(reason.active)}`}>
                        {reason.active ? 'Ativo' : 'Inativo'}
                    </span>
                </td>
            </tr>
        ));
    };

    const pageChanged = (page: number) => {
        navigate(urlNavigate(page - 1, pageSize, fieldSorted, orderSorted, txtReasonDescription), { replace: true });
    };

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, txtReasonDescription), { replace: true });
    };

    const pageSorted = (field: keyof Reason | string, order: 'ASC' | 'DESC') => {
        navigate(urlNavigate(page, pageSize, field, order, txtReasonDescription), { replace: true });
    };

    const txtReasonDescriptionChange = (description: string) => {
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, description), { replace: true });
    };

    const onFilterClean = () => {
        txtReasonDescription = '';
        navigate(urlNavigate(0, pageSize, fieldSorted, orderSorted, ''), { replace: true });
    };

    const urlNavigate = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        description: string) => {
        return `/referrals/reasons/${page}/${pageSize}/${fieldSorted}/${orderSorted}/d=${description};t=${analysisType};`;
    };

    return (
        <PageList
            pageTitle={pageTitle}
            url={url}
            breadcrumb={[{ label: 'motivos', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel="motivos"
            defaultBackendHeader={false}
            tableHeaders={[{
                id: 'actionType',
                title: 'Tipo de ação',
                field: 'actionType',
                width: '120px',
                canSort: false
            }, {
                id: 'description',
                title: 'Descrição',
                field: 'description',
                canSort: true
            }, {
                id: 'active',
                title: 'Status',
                field: 'active',
                width: '100px',
                canSort: false
            }]}
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-12 gap-1">
                        <PageSearch
                            value={txtReasonDescription}
                            label="Procurar por..."
                            onSearch={txtReasonDescriptionChange}
                            filterCleanVisible={true}
                            filterCleanEnabled={Boolean(txtReasonDescription)}
                            onFilterClean={onFilterClean}
                            configureVisible={false}
                            className="col-span-12"
                        />
                    </div>
                ),
                controlSlot: (
                    <div className='pt-4' >
                        <Link to={`form?analysisType=${analysisType}`}
                            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'>
                            Novo motivo
                        </Link>
                    </div>
                )
            }}
        </PageList>
    );
};

export default ReasonPageList;
