import { FaCheck, FaInfoCircle, FaTimes } from 'react-icons/fa';
import Tooltip from '../../atoms/Tooltip';
import SelectFilter from '../../organisms/SelectFilter';
import PageSearch from '../../molecules/PageSearch';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ASearch from '../../organisms/ASearch';
import ReferralOriginIcon from '../../ions/ReferralOriginIcon';
import PageList from '../../templates/PageList';
import CreditLimitAnalysisStateIcon from '../../ions/CreditLimitAnalysisStateIcon';
import LCBalanceCalcValue from '../../molecules/LCBalanceCalcValue';
import { Fragment } from 'react';
import NewTooltip from '../../atoms/NewTooltip';

type Referral = {
    id: number,
    referralUUId: string,
    referralId: number,
    referralDate: string,
    socialId: string,
    name: string, //fullname
    partner: string,
    partnerId: number,
    isRecurrent: boolean,
    originId: number,
    origin: string,
    originEnvironment: string,
    guaranteePercentage: number,
    scorePartner: number,
    scoreAgrolend: number,
    approvedValue: number,
    approvedValueFormated: string,
    statusName: string,
    statusId: number,
    manualAnalysisStateName: string,
    manualAnalysisApproved: boolean,
    lastAnalysisDate: string,
    observation: string,
}

const BASE_REFERRAL_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/referrals`;
const BASE_SETTLEMENT_URL = `${process.env.REACT_APP_SETTLEMENT_API_URL}/settlement`;

const PartnerAnalyzedListPage = () => {

    const navigate = useNavigate();

    const referralStatus = [
        { "value": "1", "label": "Pré-venda" },
        { "value": "2", "label": "Novo" },
        { "value": "3", "label": "Em análise" },
        { "value": "4", "label": "Aprovado" },
        { "value": "5", "label": "Reprovado" },
        { "value": "6", "label": "Aguardando produtor" },
        { "value": "7", "label": "Aguardando parceiro" },
        { "value": "8", "label": "Em andamento" },
        { "value": "9", "label": "Cancelado" },
        { "value": "10", "label": "Fechado" }
    ];

    const analysisStates = [
        { "value": "true", "label": "Aprovado" },
        { "value": "false", "label": "Reprovado" },
    ];

    const { page, pageSize, field: fieldSorted,
        order: orderSorted, filter }: any = useParams();

    let txtPartnerName = '';
    let txtReferralStatus = '';
    let txtSearch = '';
    let txtAnalysisState = '';

    if (filter)
        [txtSearch = '', txtReferralStatus = '', txtAnalysisState = '', txtPartnerName = ''] = filter.match(/=(.*?);/g)
            .map((match: string | any[]) => match.slice(1, -1));

    const url = `${BASE_REFERRAL_URL}/partner-analyzed-list?search=${txtSearch}`
        + `&page=${page}&pageSize=${pageSize}&partnerName=${txtPartnerName}`
        + `&analysisState=${txtAnalysisState}&referralStatusId=${txtReferralStatus}`;


    const renderRows = (rows: Referral[]) => {
        return rows.map((row: Referral, index: number) => (
            <tr key={row.referralId}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md border-b border border-gray-300`}>
                <td className="py-2 px-2" >{row.referralId}</td>
                <td className="py-2 px-2" >{row.referralDate}</td>
                <td className="py-2 px-2 whitespace-nowrap" >{row.socialId}</td>
                <td className="py-2 px-2 max-w-0">
                    <Tooltip content={row.name} alignment={'right'}>
                        <div className="truncate">{row.name}</div>
                    </Tooltip>
                </td>
                <td className="py-2 px-2" align='center'>
                    {row.isRecurrent
                        ?
                        <NewTooltip content='Sim'>
                            <FaCheck style={{ color: "cornflowerblue" }} />
                        </NewTooltip>
                        :
                        <NewTooltip content='Não'>
                            <FaTimes style={{ color: "rgb(242, 5, 37)" }} />
                        </NewTooltip>
                    }
                </td>

                <td className="py-2 px-2 truncate max-w-0">
                    <Tooltip content={row.partner} alignment='center'>
                        {row.partner}
                    </Tooltip>
                </td>

                <td className="py-2 px-2" align='center'>
                    <ReferralOriginIcon
                        id={row.originId}
                        title={row.origin}
                        environment={row.originEnvironment}
                    />
                </td>

                <td className="py-2 px-2" >
                    {row.guaranteePercentage}%
                </td>

                <td className="py-2 px-2 text-right" >
                    {row.scorePartner !== null ? row.scorePartner.toFixed(2) : '0.00'}
                </td>

                <td className="py-2 px-2" style={{ verticalAlign: 'middle' }} align='right'>

                    <div className="flex items-center text-right justify-end">
                        {row.scoreAgrolend}
                        <Link to={`agrolend-score/${row.referralUUId}`} className='ml-2'>
                            <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                        </Link>
                    </div>
                </td>

                <td className="py-2 px-2" align='center'>
                    <Link to={`biro-list/${row.referralUUId}/${row.partnerId}`}>
                        <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                    </Link>
                </td>

                <td className="py-2 px-2" >
                    <div className="flex items-center justify-end">
                        <span className="mr-2">{row.approvedValueFormated}</span>
                        <CreditLimitAnalysisStateIcon isApproved={row.manualAnalysisApproved} />
                    </div>
                </td>

                <td className="py-2 px-2" >
                    <div className="flex items-center justify-end">
                        <span className="mr-2">
                            <LCBalanceCalcValue
                                url={`${BASE_SETTLEMENT_URL}/partner/debt/${row.partnerId}`}
                                jsonField='value'
                                lessValue={row.approvedValue}
                                toolTip='Operações em aberto: $$'
                                formatBRL={true}
                            />
                        </span>
                    </div>
                </td>

                <td className="py-2 px-2" align='center'>
                    <Link to={`referral-detail/${row.referralUUId}`} >
                        <FaInfoCircle style={{ color: 'cornflowerblue' }} />
                    </Link>
                </td>

                <td className="py-2 px-2" align='center'>
                    {row.lastAnalysisDate}
                </td>

                <td className=''>
                    <NewTooltip content={
                        <div className="w-64 whitespace-pre-wrap break-words">
                            {row.observation?.split(";").map((item, index) => (
                                <div key={index}>{item}</div>
                            ))}
                        </div>
                    } alignment='left'>
                        <div className='truncate max-w-48'>
                            {row.observation}
                        </div>
                    </NewTooltip>

                </td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(urlNaviage(page, pageSize, fieldSorted, orderSorted, txtSearch, txtReferralStatus, txtAnalysisState, txtPartnerName), { replace: true });
    }

    const pageSizeChanged = (pageSize: number) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSearch, txtReferralStatus, txtAnalysisState, txtPartnerName), { replace: true });
    }

    const pageSorted = (field: keyof Referral | string, order: 'ASC' | 'DESC') => {
        navigate(urlNaviage(page, pageSize, field, order, txtSearch, txtReferralStatus, txtAnalysisState, txtPartnerName), { replace: true });
    }

    const txtSearchClick = (search: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, search, txtReferralStatus, txtAnalysisState, txtPartnerName), { replace: true });
    }

    const txtPartnerNameClick = (partnerName: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSearch, txtReferralStatus, txtAnalysisState, partnerName), { replace: true });
    }

    const filterReferralStatusChange = (referralState: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSearch, referralState, txtAnalysisState, txtPartnerName), { replace: true });
    }

    const filterAnalysisStateChange = (analysisState: string) => {
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, txtSearch, txtReferralStatus, analysisState, txtPartnerName), { replace: true });
    }

    const onFilterClean = () => {
        txtPartnerName = '';
        txtReferralStatus = '';
        txtSearch = '';
        navigate(urlNaviage(0, pageSize, fieldSorted, orderSorted, '', '', '', ''), { replace: true });
    }

    const urlNaviage = (page: number, pageSize: number, fieldSorted: string, orderSorted: string,
        socialId: string, referralState: string, analysisState: string, partner: string) => {
        return `/referrals/partner-analyzed/${page}/${pageSize}/${fieldSorted}/${orderSorted}/s=${socialId};r=${referralState};a=${analysisState};p=${partner};`
    }

    return (

        <PageList
            pageTitle='Pedidos Analisados - Revenda'
            url={url}
            breadcrumb={[{ label: 'Pedidos analisados', url: "#" }]}
            tableFieldSort={fieldSorted}
            tableOrderSort={orderSorted}
            onTableSort={pageSorted}
            renderRows={renderRows}
            page={page}
            pageSize={pageSize}
            onPageChange={pageChanged}
            onItemsPerPageChange={pageSizeChanged}
            itemsLabel='pedidos'
        >
            {{
                filterSlot: (
                    <div className="grid grid-cols-12 gap-1">
                        <ASearch
                            id="src-partner-name"
                            value={txtPartnerName}
                            label="Procurar por revenda..."
                            onSearch={txtPartnerNameClick}
                            className="col-span-3"
                        />

                        <SelectFilter
                            id="slc-referral-state"
                            label="Estado do pedido ..."
                            value={txtReferralStatus}
                            onChange={filterReferralStatusChange}
                            defaultVoidOption={true}
                            defaultLabel='Todos...'
                            options={referralStatus}
                            className='col-span-3'
                        />

                        <SelectFilter
                            id="slc-analysis-state"
                            label="Estado da análise ..."
                            value={txtAnalysisState}
                            onChange={filterAnalysisStateChange}
                            defaultVoidOption={true}
                            defaultLabel='Todos...'
                            options={analysisStates}
                            className='col-span-2'
                        />

                        <PageSearch
                            value={txtSearch}
                            label='Procurar por CPF ou nome do produtor...'
                            onSearch={txtSearchClick}
                            filterCleanVisible={true}
                            filterCleanEnabled={(txtPartnerName || txtReferralStatus || txtSearch) ? true : false}
                            configureVisible={false}
                            onFilterClean={onFilterClean}
                            className='col-span-4'
                        />
                    </div>),

                controlSlot: (null)
            }}
        </PageList>
    );
};

export default PartnerAnalyzedListPage;