import { Fragment, useEffect, useRef, useState } from 'react';
import { Auth } from 'aws-amplify';

import { useNavigate } from 'react-router-dom';
import TextEdit from '../../molecules/TextEdit';
import AAlert from '../../molecules/AAlert';
import SelectFilter from '../../organisms/SelectFilter';

type BusinessProperties = {
    key: string;
    value: number;
}

function MaintenanceFormPage() {

    const navigate = useNavigate();
    const BASE_URL = process.env.REACT_APP_FARMER_ANALYSIS_API_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([] as BusinessProperties[]);
    const [value, setValue] = useState('');
    const [alert, setAlert] = useState<{ message: string; type: string; visible: boolean }>({
        message: '',
        type: '',
        visible: false,
    });
    const [buttonEnabled, setButtonEnabled] = useState(true);
    const newValue = useRef(0);
    const token = useRef('');

    useEffect(() => {
        Auth.currentSession()
            .then((user) => user.getIdToken().getJwtToken())
            .then((tkn) => {
                token.current = tkn;
                return tkn;
            })
            .then((token) => fetchData(token))
            .catch((error) => {
                navigate("/denied");
                console.error('Error during authentication:', error);
            });
    }, []);

    const fetchData = async (token: string) => {
        const url = BASE_URL + `/maintenance/values`;

        const response = await apiGet(url, token);
        if (response) {
            let resp = await response.json();

            let consulta: BusinessProperties[] = Object.entries(resp).map(([key, value]) => ({
                key, value: value as number
            }));
            setData(consulta);
        }
        setIsLoading(false);
    }

    const apiGet = async (url: string, token: string) => {
        try {
            const response = await fetch(url, {

            });

            if (!response.ok) {
                navigate('/error');
                throw new Error('Network response was not ok');
            }

            return response;

        } catch (error) {
            navigate('/network-error');
            console.error('Error fetching data:', error);
        }
    }
    const handleChange = (status: string) => {
        setValue(status);
    }
    const handleTexEditChange = (value: string) => {
        newValue.current = parseFloat(value)
        setButtonEnabled(false);
    }

    const showAlert = (message: string, type: 'success' | 'error' | 'warning' | 'info') => {
        setAlert({ message, type, visible: true });
    };

    const closeAlert = () => {
        setAlert({ ...alert, visible: false });

    };

    const savePropertie = () => {
        let url = BASE_URL + "/maintenance/" + value + "/" + newValue.current;
        fetch(url, {
            method: 'PUT',
            headers: {
            },
            mode: 'cors'
        }).then((response) => {
            if (!response.ok) {
                showAlert('Erro ao salvar a propriedade', 'error');
                throw new Error('Network response was not ok');
            } else {
                showAlert('Ação realizada com sucesso!', 'success')
                fetchData(token.current);
            }
        }).catch((error) => {
            console.error('Error:', error);
        });
    }

    return (
        <div className='px-4'>
            {alert.visible && (
                <AAlert message={alert.message} type={alert.type as any} onClose={closeAlert} />
            )}
            <div className="grid grid-cols-2 gap-2">
                <div className="sticky top-0 z-10">

                    <h1 className="inline-block text-2xl text-teal-500 font-medium tracking-tight pt-2 pb-3">
                        Manutenção de Variaveis do Motor V2
                    </h1>

                </div>
            </div>
            <div>
                <div className="panel-header">
                    <SelectFilter
                        id="maintenance-properties"
                        label="Variaveis do Motor V2"
                        value={value}
                        onChange={handleChange}
                        defaultVoidOption={true}
                        defaultLabel='Selecione...'
                        options={data.map((option) => ({ value: option.key, label: option.key + ' - ' + option.value }))}
                        className='col-span-4'
                    />
                </div>
                <div className="grid grid-cols-2 gap-2 p-2">
                    <div className="col-4">
                        <TextEdit
                            label="Variavel"
                            value={value}
                            disabled
                        /></div>
                    <div className="col-4">
                        <TextEdit
                            label="Insira Novo Valor"
                            type='number'
                            placeholder=''
                            min={0}
                            onChange={(e) => handleTexEditChange(e.target.value)}
                        />
                    </div>
                </div>
                <button className='p-2 text-white bg-gradient-to-r text-white from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'
                    onClick={() => savePropertie()}
                    disabled={buttonEnabled}>
                    Salvar
                </button>
            </div>
        </div>
    )
}

export default MaintenanceFormPage