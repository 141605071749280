import { FC, useRef, useState } from "react";
import { FaUsers } from "react-icons/fa";
import ActionButton from "../../molecules/ActionButton"
import Select, { ActionMeta, SingleValue } from "react-select";
import useFetch from "../../../hooks/useFetch";
import { useNavigate } from "react-router-dom";
import CommercialConfirmModal from "./CommercialConfirmModal";
import { Auth } from "aws-amplify";
import { toast } from "react-toastify";

type Partner = {
    partnerId: number,
    partnerName: string,
    internalManager: Manager
    externalManager: Manager
}

type Option = {
    label: string;
    value: string;
}

type Manager = {
    id: string,
    name: string,
}

type ManagerSelectGroupProps = {
    partner: Partner,
    internalLabel: Option,
    externalLabel: Option,
}

type User = {
    userId: number,
    name: string,
}

const BASE_BACKOFFICE_URL = `${process.env.REACT_APP_BACKOFFICE_URL}/services/commercial`;

const ManagerSelectGroup: FC<ManagerSelectGroupProps> = ({
    partner,
    internalLabel,
    externalLabel,

}) => {

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);

    const internalOptions = useRef<Option[]>([]);
    const externalOptions = useRef<Option[]>([]);

    const internalList = useFetch(`${BASE_BACKOFFICE_URL}/get-all-internal`)
    const externalList = useFetch(`${BASE_BACKOFFICE_URL}/get-all-external`)

    const internalDefault = useRef<Option>(internalLabel);
    const externalDefault = useRef<Option>(externalLabel);

    const form = useRef<Partner>({
        ...partner
    })

    if (internalList.isTokenError) {
        navigate("/denied");
    }

    if (internalList.error) {
        navigate("/network-error");
    }

    if (internalList.data) {

        internalOptions.current = internalList.data.map((user: User) => {
            return {
                value: user.userId.toString(),
                label: user.name
            }
        })

        internalOptions.current.unshift({
            value: '',
            label: 'Vazio...'
        })
    }

    if (externalList.isTokenError) {
        navigate("/denied");
    }

    if (externalList.error) {
        navigate("/network-error");
    }

    if (externalList.data) {

        externalOptions.current = externalList.data.map((user: User) => {
            return {
                value: user.userId.toString(),
                label: user.name
            }
        })

        externalOptions.current.unshift({
            value: '',
            label: 'Vazio...'
        })

    }

    const buildModal = () => {
        return (<><div className='p-4'>
            <h2 className="text-center font-bold mt-4 mb-2">As seguintes informações serão alteradas:</h2>

            <h2 className="text-center font-bold mt-4 mb-2">Parceiro: {partner.partnerName}</h2>

            <li>Gerente interno anterior: {partner.internalManager?.name ?? 'Vazio'}</li>
            <li>Gerente externo anterior: {partner.externalManager?.name ?? 'Vazio'}</li>
            <br />
            <li>Novo gerente interno: {form.current?.internalManager?.name ?? 'Vazio'}</li>
            <li>Novo gerente externo: {form.current?.externalManager?.name ?? 'Vazio'}</li>


            <h2 className="text-center font-bold mt-4 mb-2">Confirma a alteração?</h2>
        </div>
            <div className="p-4">
                <ActionButton
                    type='button'
                    className='text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'
                    onClick={updateAssociate}
                    label={
                        <div className="flex items-center">
                            <FaUsers className='mr-2' />
                            <span>Confirmar</span>
                        </div>}
                /></div>
        </>
        )
    }

    const closeModal = () => {
        setShowModal(false);
    }

    const handleModalConfirm = () => {
        setShowModal(true);
    }

    const updateAssociate = async () => {
        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        const response = await fetch(BASE_BACKOFFICE_URL + "/change-associate", {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                partnerId: form.current?.partnerId,
                internalManagerId: form.current?.internalManager?.id ?? '',
                externalManagerId: form.current?.externalManager?.id ?? '',
            })
        });

        if (response.ok) {
            console.log(response.body);

            toast.info("Dados atualizados com sucesso...");
            navigate(-1);
        } else {
            const { message, errors, title } = await response.json();
            toast.error(message);
        }
    }

    const internalOnChange = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {

        const option = newValue as Option;
        form.current = {
            ...form.current,
            internalManager: { id: option.value, name: option.label },
        }

    }

    const externalOnChange = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
        const option = newValue as Option;
        form.current = {
            ...form.current,
            externalManager: { id: option.value, name: option.label },
        }

    }

    return (<div>
        <CommercialConfirmModal
            handleClose={closeModal}
            visible={showModal}
            children={buildModal()} />
        <div className="grid grid-cols-2 gap-1">

            <div id="external-select">
                <label htmlFor="external-select"
                    className="text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                    Gerente Externo
                </label>
                <Select
                    options={externalOptions.current}
                    defaultValue={externalDefault.current}
                    onChange={externalOnChange}
                    placeholder="Gerente Externo"
                />
            </div>

            <div id="internal-select">
                <label htmlFor="internal-select"
                    className="text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">
                    Gerente Interno
                </label>
                <Select
                    options={internalOptions.current}
                    defaultValue={internalDefault.current}
                    onChange={internalOnChange}
                    placeholder="Gerente Interno"
                />
            </div>

        </div>
        <ActionButton type='button'
            className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'
            onClick={handleModalConfirm}
            label={
                <div className="flex items-center">
                    <FaUsers className='mr-2' />
                    <span>Associar</span>
                </div>
            }
        />
    </div>
    );
}

export default ManagerSelectGroup