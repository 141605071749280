import { Fragment, useEffect, useRef } from 'react';
import PageForm from '../../templates/PageForm';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSignal } from '@preact/signals-react';
import CurrencyInput from '../../molecules/CurrencyInput';
import TextEdit from '../../molecules/TextEdit';
import useFetch from '../../../hooks/useFetch';
import { IoArrowRedo, IoArrowUndo } from 'react-icons/io5';
import { MdChecklistRtl, MdOutlineCheck } from 'react-icons/md';
import AnalysisRecommendationIcon from '../../ions/AnalysisRecommendationIcon';
import { Auth } from 'aws-amplify';
import ReferralCreditLimitDisplay from '../../ions/ReferralCreditLimitDisplay';
import InputFrame from '../../atoms/InputFrame';
import ErrorMessage from '../../atoms/ErrorMessage';

import { ErrorField } from '../../../types/ErrorField';
import { currencyWithPrefix, concurrencyWithoutPrefix } from '../../../utils/currencyUtils';
import { getErrorMessage, hasErrorMessage, removeErrorField } from '../../../utils/formErrorUtils';

const ANALYSIS_TYPE = ['form-analysis-approve', 'form-analysis-reject', 'form-analysis-manual-approve', 'form-analysis-manual-reject'];
const PARTNER_ANALYSIS_LIST_PAGE = "/referrals/partner-analysis/0/100/referral.id/ASC";
const CREDIT_LIMIT_ANALYSIS_FORM_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/credit-limit-analysis/form`;
const CREDIT_LIMIT_ANALYSIS_POST_URL = `${process.env.REACT_APP_REFERRAL_API_URL}/credit-limit-analysis`;

const titleMap: { [key: string]: string } = {
    'form-analysis-approve': 'Aprovação de análise',
    'form-analysis-reject': 'Reprovação de análise',
    'form-analysis-manual-approve': 'Aprovação manual de análise',
    'form-analysis-manual-reject': 'Reprovação manual de análise'
};

interface Reason {
    id: string;
    description: string;
    total: number;
}

interface Analysis {
    id: string;
    socialId: string;
    createdAt: string;
    farmerName: string;
    partnerGuarantorName: string
    partnerOriginName: string
    m0creditRuralLimit: number;
    m0creditRuralLimitFormatted: string;
    ruralCreditLimitPercent: string;
    creditLimit: number;
    creditLimitFormatted: string;
    reasons: Reason[];
    recommendationCode: string;
    recommendation: string;
    percentGreenSignal: number;
    percentYellowSignal: number;
    decimalPlacesPercentInput: number;
}

type Form = {
    referralId: string;
    analysisAction: string;
    ruralCreditPercent: number;
    creditLimit: number;
    creditLimitFormatted: string;
    reasons: Reason[];
}


const CreditLimitAnalysisForm = () => {

    const navigate = useNavigate();
    const { referralId = "", analysisType = "" } = useParams();

    const inputRef = useRef<HTMLInputElement>(null);

    if (!referralId || !ANALYSIS_TYPE.includes(analysisType)) navigate("/not-found");

    const isLoadingForm = useSignal(false);

    const form = useSignal<Form>({
        referralId: referralId,
        analysisAction: analysisType.replace("form-", ""),
        ruralCreditPercent: 0,
        creditLimit: 0,
        creditLimitFormatted: "R$ 0,00",
        reasons: []
    });

    const errorFields = useSignal<ErrorField[]>([]);
    const { isLoading, data, error, isTokenError } = useFetch(`${CREDIT_LIMIT_ANALYSIS_FORM_URL}/${analysisType.replace("form-", "")}/${referralId}`);

    const apiVersion = useRef('');
    const analysis = useSignal<Analysis>({
        id: "",
        socialId: "",
        createdAt: "",
        farmerName: "",
        partnerGuarantorName: "",
        partnerOriginName: "",
        m0creditRuralLimit: 0,
        m0creditRuralLimitFormatted: "R$ 0,00",
        ruralCreditLimitPercent: "0",
        creditLimit: 0,
        creditLimitFormatted: "R$ 0,00",
        reasons: [],
        recommendationCode: "",
        recommendation: "",
        percentGreenSignal: 0,
        percentYellowSignal: 0,
        decimalPlacesPercentInput: 0
    });

    const title = useSignal<string>("");

    if (isTokenError) {
        navigate("/denied");
    }

    if (error) {
        navigate("/network-error");
    }
    useEffect(() => {
        if (data) {
            apiVersion.current = `${data.apiName} - ${data.apiVersion}`;

            if (data.error) {
                navigate(-1);
                toast.error(data.messageError);
                return;
            }

            analysis.value = data.presenter;
            title.value = `${titleMap[analysisType] || 'Tipo desconhecido'} #${analysis.value.id}`;
            changeForm("ruralCreditPercent", Number(analysis.value.ruralCreditLimitPercent));
            changeForm("creditLimit", analysis.value.creditLimit);

            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [data]);

    const changeForm = (field: string, value: any) => {
        if (field === "creditLimit") {
            let ruralCreditPercent = 0;
            if (analysis.value.m0creditRuralLimit !== 0) {
                if (analysis.value.decimalPlacesPercentInput === 0)
                    ruralCreditPercent = Math.floor((value * 100) / analysis.value.m0creditRuralLimit);
                else
                    ruralCreditPercent = (value * 100) / analysis.value.m0creditRuralLimit;
            }
            form.value = {
                ...form.value,
                creditLimit: value,
                ruralCreditPercent,
                creditLimitFormatted: currencyWithPrefix(value),
            };

        } else if (field === "ruralCreditPercent") {
            if (analysis.value.m0creditRuralLimit !== 0) {
                const creditLimit = Math.floor((value * analysis.value.m0creditRuralLimit) / 100);
                form.value = {
                    ...form.value,
                    ruralCreditPercent: value,
                    creditLimit,
                    creditLimitFormatted: currencyWithPrefix(creditLimit),
                };
            }
        } else {
            form.value = { ...form.value, [field]: value };
        }

        removeErrorField(errorFields.value, field);
    };

    const handleAddReason = (reason: Reason) => {

        if (!form.value.reasons.includes(reason)) {
            form.value = {
                ...form.value,
                reasons: [...form.value.reasons, reason]
            };
        }
    };

    const handleRemoveReason = (reason: Reason) => {
        form.value = {
            ...form.value,
            reasons: form.value.reasons.filter(r => r.id !== reason.id)
        };
    };

    const submitForm = async () => {
        isLoadingForm.value = true;

        const token = await Auth.currentSession()
            .then((user) => user.getIdToken()
                .getJwtToken());

        const response = await fetch(CREDIT_LIMIT_ANALYSIS_POST_URL, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                referralId: form.value.referralId,
                analysisAction: form.value.analysisAction,
                ruralCreditPercent: form.value.ruralCreditPercent,
                creditLimit: form.value.creditLimit,
                reasonIds: form.value.reasons.map(reason => reason.id)
            })
        });

        if (response.ok) {
            toast.success(`${titleMap[analysisType]} realizada com sucesso!`);
            navigate(-1);
        } else {
            const { message, errors } = await response.json();
            toast.error(message);
            errorFields.value = errors ? errors : [];
        }
        isLoadingForm.value = false;
    };

    const cancelForm = () => {
        navigate(PARTNER_ANALYSIS_LIST_PAGE);
    }

    const getColorClass = (value: string | number): string => {
        const numericValue = typeof value === 'string' ? Number(value) : value;

        if (isNaN(numericValue)) return 'text-red-600';
        if (numericValue <= analysis.value.percentGreenSignal) return 'text-green-600';
        if (numericValue <= analysis.value.percentYellowSignal) return 'text-yellow-600';
        return 'text-red-600';
    };

    return (
        <PageForm
            pageTitle={title.value}
            apiVersion={apiVersion.current}
            isLoading={isLoading || isLoadingForm.value}
            breadcrumb={[{ label: 'Pedidos da revenda', url: PARTNER_ANALYSIS_LIST_PAGE }, { label: title.value, url: '#' }]}
        >
            {{
                controlSlot: (
                    <div className='pt-4 flex justify-end space-x-2'>
                        {isLoading ? (
                            <div className="h-10 w-28 bg-gray-300 animate-pulse rounded" />
                        ) : (
                            <>
                                <button type='button'
                                    className='text-black bg-gradient-to-r from-slate-200 via-slate-300 to-slate-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed'
                                    onClick={cancelForm}
                                    disabled={isLoadingForm.value || isLoadingForm.value}
                                >
                                    <div className="flex items-center">
                                        Cancelar
                                    </div>
                                </button>

                                {(analysisType === 'form-analysis-approve') && (
                                    <button
                                        type='button'
                                        className={`text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                        onClick={submitForm}
                                        disabled={isLoadingForm.value}
                                    >
                                        Aprovar
                                    </button>
                                )}

                                {(analysisType === 'form-analysis-reject') && (
                                    <button
                                        type='button'
                                        className={`text-white bg-gradient-to-r from-red-500 via-red-600 to-red-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                        onClick={submitForm}
                                        disabled={isLoadingForm.value}
                                    >
                                        Reprovar
                                    </button>
                                )}

                                {(analysisType === 'form-analysis-manual-approve') && (
                                    <button
                                        type='button'
                                        className={`text-white bg-gradient-to-r from-green-500 via-green-600 to-green-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                        onClick={submitForm}
                                        disabled={form.value.reasons.length == 0 || isLoadingForm.value}
                                    >
                                        Aprovar manualmente
                                    </button>
                                )}

                                {(analysisType === 'form-analysis-manual-reject') && (
                                    <button
                                        type='button'
                                        className={`text-white bg-gradient-to-r from-pink-600 via-pink-700 to-pink-800 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2 disabled:opacity-50 disabled:cursor-not-allowed`}
                                        onClick={submitForm}
                                        disabled={form.value.reasons.length == 0 || isLoadingForm.value}
                                    >
                                        Reprovar manualmente
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                ),
                formSlot: (
                    <div className="container mx-auto px-4 py-8 flex">
                        {isLoading ? (
                            <div className="w-2/3 space-y-4">
                                <div className="h-4 bg-gray-300 rounded animate-pulse" />
                                <div className="h-4 bg-gray-300 rounded animate-pulse" />
                                <div className="h-8 bg-gray-300 rounded animate-pulse" />
                            </div>
                        ) : (
                            <div className="w-2/3">
                                <div className="grid grid-cols-4 gap-4 mb-4">
                                    <div className="col-span-1">
                                        <TextEdit label="Data do pedido:" value={analysis.value.createdAt} disabled />
                                    </div>
                                    <div className="col-span-1">
                                        <TextEdit label="CPF:" value={analysis.value.socialId} disabled />
                                    </div>
                                    <div className="col-span-2">
                                        <TextEdit label="Nome do produtor:" value={analysis.value.farmerName} disabled />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="col-span-2">
                                        <TextEdit label="Parceira de origem:" value={analysis.value.partnerOriginName} disabled />
                                    </div>
                                </div>

                                <div className="mb-4">
                                    <div className="col-span-2">
                                        <TextEdit label="Parceira avalista:" value={analysis.value.partnerGuarantorName} disabled />
                                    </div>
                                </div>

                                <div className="grid grid-cols-12 gap-4 mb-4">
                                    <div className='col-span-4'>
                                        {analysisType === 'form-analysis-manual-approve' ?
                                            <CurrencyInput
                                                label='L.C. aprovado:'
                                                ref={inputRef}
                                                prefix='R$'
                                                value={form.value.creditLimit}
                                                onChange={(value) => changeForm("creditLimit", Number(value))}
                                                errorMessage={getErrorMessage(errorFields.value, "creditLimit")}
                                                disabled={isLoadingForm.value}
                                            />
                                            : <TextEdit prefix='R$' label="L.C. sugerido:" value={concurrencyWithoutPrefix(analysis.value.creditLimit)} disabled />
                                        }
                                    </div>

                                    <div className='col-span-2'>
                                        <AnalysisRecommendationIcon
                                            className='text-3xl'
                                            code={analysis.value.recommendationCode}
                                            description={analysis.value.recommendation} />
                                    </div>

                                    {(analysisType === 'form-analysis-manual-approve') ?
                                        <Fragment>
                                            <div className='col-span-2'>
                                                <CurrencyInput label='L.C./C.R. M0:'
                                                    textColor={getColorClass(form.value.ruralCreditPercent)}
                                                    prefix='%'
                                                    fractionDigits={analysis.value.decimalPlacesPercentInput}
                                                    value={form.value.ruralCreditPercent}
                                                    onChange={(value) => changeForm("ruralCreditPercent", Number(value))}
                                                    errorMessage={getErrorMessage(errorFields.value, "ruralCreditPercent")}
                                                    disabled={isLoadingForm.value}
                                                />
                                            </div>
                                            <div className='col-span-4'>
                                                <span className="p-2 bg-slate-500 text-white rounded-lg shadow-sm mt-3">
                                                    {`${form.value.creditLimitFormatted} / ${analysis.value.m0creditRuralLimitFormatted} `}
                                                </span>
                                            </div>
                                        </Fragment>
                                        :
                                        <div className='col-span-3'>
                                            <InputFrame label="L.C./C.R. M0:" className='p-3'>
                                                <ReferralCreditLimitDisplay
                                                    creditLimitFormatted={analysis.value.creditLimitFormatted}
                                                    limitCreditDivideCreditRuralInPercent={analysis.value.ruralCreditLimitPercent}
                                                    recommendation={analysis.value.recommendationCode}
                                                    creditRuralFormattedValue={analysis.value.m0creditRuralLimitFormatted}
                                                    creditRuralStatus="ok" />
                                            </InputFrame>
                                        </div>
                                    }

                                </div>

                                {hasErrorMessage(errorFields.value, "referralId") ?
                                    <ErrorMessage message={getErrorMessage(errorFields.value, "referralId")} />
                                    : null}

                                <div id="reasons" className="mb-4 flex flex-col items-center justify-center max-w-full h-96 border-2 border-dashed rounded-lg bg-gray-50 hover:bg-gray-100">
                                    {form.value.reasons.length === 0 && (
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <MdChecklistRtl className="text-4xl text-gray-500 " />
                                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                                <span className="font-semibold">Motivo(s) selecionados para aprovação.</span>
                                            </p>
                                        </div>
                                    )}
                                    <ul className="w-full flex-grow overflow-y-auto">
                                        {form.value.reasons.map((reason) => (
                                            <li key={`selected-reason-${reason.id}`}
                                                onClick={() => handleRemoveReason(reason)}
                                                className="cursor-pointer p-2 border border-blue-300 rounded m-2 bg-white">
                                                <div className="flex items-center justify-between min-w-[450px]">
                                                    <span>{reason.description}</span>
                                                    <IoArrowRedo className="text-red-800 ml-2" />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )}

                        <div className="w-1/3 pl-4">
                            {isLoading ? (
                                <div className="space-y-4">
                                    <div className="h-4 bg-gray-300 rounded animate-pulse" />
                                    <div className="h-4 bg-gray-300 rounded animate-pulse" />
                                    <div className="h-4 bg-gray-300 rounded animate-pulse" />
                                </div>
                            ) : (
                                <>
                                    <h3 className='font-bold'>Motivos para aprovação</h3>
                                    <ul className="border border-blue-300 p-2 rounded">
                                        {analysis.value.reasons.map((reason) => (
                                            <li
                                                key={`reason-${reason.id}`}
                                                onClick={() => handleAddReason(reason)}
                                                className={`p-2 m-1 border border-blue-100 ${form.value.reasons.includes(reason) ? 'text-gray-400 bg-slate-50' : 'bg-white cursor-pointer hover:border-blue-500'}`}
                                            >
                                                <div className="grid grid-cols-10">
                                                    {form.value.reasons.includes(reason) ?
                                                        < MdOutlineCheck />
                                                        : <IoArrowUndo className="col-span-1 text-lime-700" />}
                                                    <span className='col-span-9'>
                                                        {reason.description}
                                                    </span>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                )
            }}
        </PageForm >
    );
};

export default CreditLimitAnalysisForm;
