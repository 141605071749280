import { Fragment, useEffect, useRef, useState } from 'react';

import ATable from "../../organisms/ATable";
import PageHeader from '../../molecules/PageHeader';
import { Auth } from 'aws-amplify';
import BadgePartnerStatus from '../../atoms/BadgePartnerStatus';
import { FaCopy, FaExternalLinkAlt } from 'react-icons/fa';
import PageSearch from '../../molecules/PageSearch';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Loading from '../../ions/Loading';
import TableLoading from '../../ions/TableLoading';
import APageFooter from '../../molecules/PageFooter';

interface Partner {
    id: number;
    uuid: string;
    fantasyName: string;
    contactName: string;
    fullLink: string;
    alias: string;
    govTaxId: string;
    status: string;
    statusId: number;
    active: boolean;
    type: string;
}

type SortDirection = "ASC" | "DESC";

function PartnerListPage() {

    const location = useLocation();

    const [searchParams] = useSearchParams();
    const page = parseInt(searchParams.get('pg') || "0");
    const search = searchParams.get('sr') || '';
    const fieldSorted = useRef(searchParams.get('fs') || "legalPerson.fantasyName");
    const orderSorted = useRef<SortDirection>((searchParams.get('os') as SortDirection) || "ASC");

    const [isLoading, setIsLoading] = useState(true);

    const token = useRef('');
    const rows = useRef<Partner[]>([]);
    const columns = useRef([]);
    const size = useRef(0);
    const pageSize = useRef(40);
    const apiVersion = useRef('');
    const totalPages = useRef(0);
    const totalElements = useRef(0);
    const numberOfElements = useRef(0);

    const navigate = useNavigate();

    const BASE_URL = process.env.REACT_APP_PARTNER_API_URL;

    useEffect(() => {
        Auth.currentSession()
            .then((user) => user.getIdToken().getJwtToken())
            .then((tkn) => {
                token.current = tkn;
                return tkn;
            })
            .then((token) => fetchData(token))
            .catch((error) => {
                navigate("/denied");
                console.error('Error during authentication:', error);
            });
    }, []);

    useEffect(() => {
        if (location.search && token.current) {
            setIsLoading(true);
            fetchData(token.current);
        }

    }, [location.search]);

    const fetchData = async (token: string) => {
        const txtSearch = search.replace(/[^a-zA-Z0-9]/g, '');
        const url = BASE_URL + `/page/partner-list?search=${txtSearch}`
            + `&page=${page}&size=${pageSize.current}&sort=${fieldSorted.current},${orderSorted.current}`;

        const response = await apiGet(url, token);
        if (response) {
            const data = await response.json();
            size.current = data.size;
            pageSize.current = data.pageSize;
            totalPages.current = data.totalPages;
            totalElements.current = data.totalElements;
            numberOfElements.current = data.numberOfElements;

            if (fieldSorted.current === null)
                fieldSorted.current = data.sortedColumn.field;

            if (orderSorted.current === null)
                orderSorted.current = data.sortedColumn.order;

            apiVersion.current = `${data.apiName} - ${data.apiVersion}`;
            columns.current = data.headers;

            rows.current = data.list;
        }
        setIsLoading(false);
    }

    // TODO criar um componente de apis
    const apiGet = async (url: string, token: string) => {
        try {
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                mode: 'cors'
            });

            if (!response.ok) {
                navigate('/error');
                throw new Error('Network response was not ok');
            }

            return response;

        } catch (error) {
            navigate('/network-error');
            console.error('Error fetching data:', error);
        }
    }

    const renderRows = () => {
        if (rows.current.length === 0) {
            return <tr>
                <td colSpan={columns.current.length} className="py-2 px-4 border-b border-gray-300 text-center">
                    <div className="flex items-center bg-blue-500 text-white text-sm font-bold px-4 py-3" role="alert">
                        <svg className="fill-current w-4 h-4 mr-2"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20">
                            <path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" />
                        </svg>
                        <p>Nenhum registro encontrado.</p>
                    </div>
                </td>
            </tr>
        }

        return rows.current.map((row: Partner, index) => (
            <tr key={index}
                className={`${index % 2 === 0 ? 'bg-slate-100' : ''} hover:bg-slate-300 hover:shadow-md`}
                onClick={() => openView(row.id)}
                style={{ cursor: 'pointer' }}
            >
                <td className="py-2 px-4">{row.id}</td>
                <td className="py-2 px-4 text-center">
                    <BadgePartnerStatus statusId={row.statusId} status={row.status} />
                </td>
                <td className="py-2 px-4">{row.govTaxId}</td>
                <td className="py-2 px-4">{row.fantasyName}</td>
                <td className="py-2 px-4">
                    <div className="flex items-center">
                        <a id={`btn-copy-${row.id}`} href="#" className="text-secondary">
                            <FaCopy />
                        </a>
                        <span className="ml-2">{row.alias}</span>
                        <a href={row.fullLink} target="_blank" id={`link-alias-id-${row.id}`} className="ml-2 text-blue-500">
                            <FaExternalLinkAlt />
                        </a>
                    </div>
                </td>
                <td className="py-2 px-4">{row.contactName}</td>
            </tr>
        ));
    }

    const pageChanged = (page: number) => {
        page = page - 1;
        navigate(`?pg=${page}&sr=${search}&fs=${fieldSorted.current}&os=${orderSorted.current}`, { replace: true });
    }

    const pageSorted = (field: keyof Partner | string, order: 'ASC' | 'DESC') => {
        fieldSorted.current = field;
        orderSorted.current = order;
        navigate(`?pg=${page}&sr=${search}&fs=${field}&os=${order}`, { replace: true });
    }

    const pageSearch = (search: string) => {
        navigate(`?pg=${0}&sr=${search}&fs=${fieldSorted.current}&os=${orderSorted.current}`, { replace: true });
    }

    const newPartnerAction = () => {
        navigate('/partners/form', { replace: true });
    }

    const openView = (id: number) => {
        navigate(`/partners/view/${id}`)
    }

    return (
        <div className='px-4'>
            {isLoading && <Loading />}
            <PageHeader title='Parceiras'
                apiVersion={apiVersion.current}
                breadcrumb={[{ label: 'Parceiras', url: "#" }]}
            >
                <div className='pt-4'>
                    <button type='button'
                        className='text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2'
                        onClick={newPartnerAction}>Nova Parceira</button>
                </div>
            </PageHeader>

            {isLoading ? <TableLoading /> :
                <Fragment>
                    <PageSearch
                        value={search}
                        label='Procurar por Nome, CNPJ ou contato...'
                        onSearch={pageSearch}
                        filterCleanVisible={false}
                        configureVisible={true}
                    />

                    <ATable headers={columns.current}
                        fieldSorted={fieldSorted.current || 'id'}
                        orderSorted={orderSorted.current}
                        onSort={pageSorted}
                        autoResize={true}
                    >
                        {renderRows()}
                    </ATable>
                    <APageFooter currentPage={page + 1}
                        totalPages={totalPages.current}
                        itemsPerPage={size.current}
                        totalElements={totalElements.current}
                        onPageChange={pageChanged}
                        itemsLabel='parceiras'
                    />
                </Fragment>}
        </div>
    );
};

export default PartnerListPage;