import { Auth } from 'aws-amplify';

export const useAmplifly = () => ({
    validateToken: async (token: string) => {
        console.log('Validating token...');

        //TODO
        const user = await Auth.currentAuthenticatedUser();

        if (user) {
            const roles = user.signInUserSession.accessToken.payload['cognito:groups'];
            return {
                user: {
                    id: user.attributes.sub,
                    username: user.username,
                    email: user.attributes.email,
                    name: user.attributes.name,
                    roles
                },
                token
            };
        }

        return {
            user: null,
            token: ''
        }

    },

    signin: async (username: string, password: string) => {
        try {
            const user = await Auth.signIn({ username, password });

            const idToken = user.signInUserSession.idToken.jwtToken;
            const idTokenPayload = user.signInUserSession.idToken.payload;
            const accessToken = user.signInUserSession.accessToken.jwtToken;
            const roles = user.signInUserSession.accessToken.payload['cognito:groups'];

            return {
                user: {
                    id: user.attributes.sub,
                    username: user.username,
                    email: user.attributes.email,
                    name: idTokenPayload.name || '',
                    profile: idTokenPayload.profile || '',
                    roles
                },
                token: idToken,
                accessToken
            };

        } catch (err) {
            console.log(err);
            return {
                user: null,
                token: ''
            };
        }
    },

    signout: async () => {
        try {
            await Auth.signOut();
        } catch (error) {
            console.log('error signing out: ', error);
        }

    }
})