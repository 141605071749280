import { FC } from "react";
import { FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import NewTooltip from "../atoms/NewTooltip";

type ManualAnalysisStateProps = {
    isApproved: boolean;
}

const CreditLimitAnalysisStateIcon: FC<ManualAnalysisStateProps> = ({ isApproved }) => {

    const description = isApproved ? "Aprovado" : "Reprovado";

    const icon = isApproved ? <FaThumbsUp style={{ color: "cornflowerblue" }} />
        : <FaThumbsDown style={{ color: "rgb(242, 5, 37)" }} />

    return (
        <NewTooltip content={description}>
            {icon}
        </NewTooltip>
    );
}

export default CreditLimitAnalysisStateIcon;